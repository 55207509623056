import { Component, EventEmitter, Input, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from '@vg-constellation/angular-16/card';
import { IconComponent } from '@vg-constellation/angular-16/icon';
import { AccessLevelTileComponent } from '../../../tiles/access-level-tile/access-level-tile.component';
import {
    updateAddUserStepperSelectedAccess,
    updateAddUserStepperSelectedAccount,
} from '../../../../store/actions/add-user-stepper-flow.action';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { AdobeLaunchService } from '../../../../services/adobe-launch/adobe-launch.service';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { environment } from 'src/environments/environment';
import { IneligibleReasons } from '../../../../../common/enums/ineligible-scenarios.enum';
import {
    ModalDialogComponent,
    ModalDialogEntrypointComponent,
} from '@vg-constellation/angular-16/modal-dialog';
import { Content } from '../../../../../common/interfaces/content.interface';
import { ContentUtil } from '../../../../utils/content/content.util';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { adobeConfigData } from './gaf-access-level-mvp-adobe';
import { selectAddUserStepperSelectedAccount } from '../../../../store/selectors/grant-revoke-permissions.selector';
import {
    selectAccountById,
    selectedAccountIsIneligibleFor,
} from '../../../../store/selectors/account-permissions.selector';
import { take, tap } from 'rxjs';
import { OwnAccountDTO } from '../../../../../common/dtos/own-account.dto';
import { ACCOUNT_ID_PARAM } from '../../../../constants/application-routes';

@AdobeTagging(adobeConfigData)
@Component({
    selector: 'zci-gaf-access-level',
    standalone: true,
    imports: [
        CommonModule,
        CardComponent,
        IconComponent,
        AccessLevelTileComponent,
        ModalDialogComponent,
        ModalDialogEntrypointComponent,
    ],
    templateUrl: './gaf-access-level-mvp.component.html',
})
export class GafAccessLevelMvpComponent implements OnInit {
    @Input() nextStepReady: EventEmitter<any>;

    @ViewChild('selectAccessModalDialogue', { static: false })
    selectAccessModalDialogue!: ModalDialogComponent;

    @ViewChild('cashPlusModalDialogue', { static: false })
    cashPlusModalDialogue!: ModalDialogComponent;

    accountId: string;
    environment = environment;
    content: Content = ContentUtil.content;
    ineligibleReasons: IneligibleReasons[] = [];
    headingText: string = '';
    bodyText: string = '';
    cashManagementAccount: boolean;
    selectedAccess: AccessTypeSelection;

    constructor(
        private readonly store: Store,
        private readonly route: ActivatedRoute,
        protected adobeLaunchService: AdobeLaunchService,
        private readonly activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.accountId = this.activatedRoute.snapshot.params[ACCOUNT_ID_PARAM];
        this.storeSelectedAccount();

        this.store.select(selectedAccountIsIneligibleFor(this.accountId)).subscribe((data) => {
            this.ineligibleReasons = data;
        });

        this.store.select(selectAddUserStepperSelectedAccount).subscribe((data) => {
            this.cashManagementAccount = data?.cashManagementAccount;
        });
    }

    openCashPlusModalDialog() {
        this.cashPlusModalDialogue.openModalDialog();
    }

    selectInfoOnlyAndNavigate(): void {
        this.selectedAccess = AccessTypeSelection.INFORMATION_ONLY;
        if (this.cashManagementAccount === true) {
            this.openCashPlusModalDialog();
        } else {
            this.store.dispatch(
                updateAddUserStepperSelectedAccess({
                    selectedAccess: this.selectedAccess,
                }),
            );
            this.nextStepReady.emit(true);
        }
    }

    selectLimitedAndNavigate(): void {
        this.selectedAccess = AccessTypeSelection.LIMITED_ACCESS;
        if (this.ineligibleReasons.includes(IneligibleReasons.MANAGED_ACCOUNT)) {
            this.headingText = this.content.addUserFlow.mvp.accessLevel.limitedModal.headingText;
            this.bodyText = this.content.addUserFlow.mvp.accessLevel.limitedModal.bodyText;
            this.selectAccessModalDialogue.openModalDialog();
        } else {
            this.store.dispatch(
                updateAddUserStepperSelectedAccess({
                    selectedAccess: this.selectedAccess,
                }),
            );
            this.nextStepReady.emit(true);
        }
    }

    navigateToForm(): void {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    onContinueModal(): void {
        this.store.dispatch(
            updateAddUserStepperSelectedAccess({
                selectedAccess: this.selectedAccess,
            }),
        );
        this.nextStepReady.emit(true);
    }

    private storeSelectedAccount() {
        this.store
            .select(selectAccountById(this.accountId))
            .pipe(
                take(1),
                tap((selectedAccount: OwnAccountDTO) => {
                    this.store.dispatch(
                        updateAddUserStepperSelectedAccount({
                            selectedAccount,
                        }),
                    );
                }),
            )
            .subscribe();
    }
}
