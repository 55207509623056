import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { VgnService } from 'src/app/services/vgn/vgn.service';
import { selectContactPersonType } from 'src/app/store/selectors/account-permissions.selector';
import { CONTENT_PLACEHOLDERS } from 'src/common/constants/constants';
import { environment } from 'src/environments/environment';
import {
    AdobeAnalyticsCallToAction,
    AnalyticsCtaType,
} from '../../../services/adobe-launch/adobe-launch.cta';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { AdobeLaunchService } from '../../../services/adobe-launch/adobe-launch.service';
import { ContentUtil } from '../../../utils/content/content.util';

@Component({
    selector: 'zci-technical-difficulties-page',
    templateUrl: './technical-difficulties-page.component.html',
    styleUrls: ['./technical-difficulties-page.component.scss'],
})
export class TechnicalDifficultiesPageComponent implements OnInit, OnDestroy {
    environment = environment;

    content = ContentUtil.grantorFragmentContent;

    contactPersonType$ = this.store.select(selectContactPersonType);

    contactPersonTypePlaceHolder: string = CONTENT_PLACEHOLDERS.CONTACT_PERSON_TYPE;

    popStateHandler = () => {
        window.location.reload();
    };

    constructor(
        private store: Store,
        private readonly vgnService: VgnService,
        private adobeLaunchService: AdobeLaunchService,
    ) {}

    ngOnInit(): void {
        this.vgnService.setMinimalNav(true);
        this.adobeLaunchService.pageLoad(AdobePageName.TECHNICAL_DIFFICULTIES_PAGE);
        this.refreshOnBackButtonClick();
    }

    ngOnDestroy(): void {
        this.vgnService.setMinimalNav(false);
        window.removeEventListener('popstate', this.popStateHandler);
    }

    refreshOnBackButtonClick(): void {
        window.addEventListener('popstate', this.popStateHandler);
    }

    trackReturnToProfileAndAccountSettingsLink(): void {
        const callToActionData: AdobeAnalyticsCallToAction = {
            ctaName: 'return_to_profile_and_account_settings',
            location: 'give_us_a_call',
            ctaType: AnalyticsCtaType.LINK,
        };
        this.adobeLaunchService.trackAction(callToActionData);
    }
}
