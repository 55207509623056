import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    AcceptPermissionResponseDTO,
    GrantPermissionResponseDTO,
    RemovePermissionResponseDTO,
} from 'src/common/dtos/access-permissions.dto';
import { environment } from 'src/environments/environment';

import {
    GranteeDetailsInterface,
    GrantorDetailsInterface,
} from '../../../common/interfaces/access-permissions.interface';
import { RegulatoryQuestionsInterface } from '../../../common/interfaces/regulatory-questions.interface';

@Injectable({
    providedIn: 'root',
})
export class AccessPermissionsService {
    constructor(private readonly httpClient: HttpClient) {}

    grantPermission(
        granteeDetails: GranteeDetailsInterface,
        grantorDetails: GrantorDetailsInterface,
        accountName: string,
    ): Observable<GrantPermissionResponseDTO> {
        return this.httpClient.post<GrantPermissionResponseDTO>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.GRANT_PERMISSION}`,
            {
                granteeDetails,
                grantorDetails,
                accountName,
            },
        );
    }

    grantPermissionMvp(
        granteeDetails: GranteeDetailsInterface,
        grantorDetails: GrantorDetailsInterface,
        accountName: string,
    ): Observable<GrantPermissionResponseDTO> {
        return this.httpClient.post<GrantPermissionResponseDTO>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.GRANT_PERMISSION_MVP}`,
            {
                granteeDetails,
                grantorDetails,
                accountName,
            },
        );
    }

    removePermission(
        granteeDetails: GranteeDetailsInterface,
        grantorDetails: GrantorDetailsInterface,
    ): Observable<RemovePermissionResponseDTO> {
        return this.httpClient.post<GrantPermissionResponseDTO>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.REMOVE_PERMISSION}`,
            {
                granteeDetails,
                grantorDetails,
            },
        );
    }

    acceptPermission(
        token: string,
        granteeRegulatoryQuestions?: RegulatoryQuestionsInterface,
    ): Observable<AcceptPermissionResponseDTO> {
        return this.httpClient.post<any>(
            `${environment.ZCI_API_BASE_URL}${environment.ZCI_API_ENDPOINTS.ACCEPT_PERMISSION}`,
            {
                token,
                granteeRegulatoryQuestions,
            },
        );
    }
}
