import { GranteeState } from './grantee.state';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';

export const granteeInitialState: GranteeState = {
    accessType: AccessTypeSelection.LIMITED_ACCESS,
    granteeIneligibleReason: null,
    isLoading: false,
    error: null,
    granteeRegulatoryQuestions: null,
};
