export enum AdobeAnalyticsProcessType {
    GAF_GRANT_ACCESS_FLOW = 'grant_access_flow',
    GAF_LEGACY_GRANT_ACCESS_FLOW = 'legacy_grant_access_flow',
    RAF_REMOVE_ACCESS_FLOW = 'remove_access_flow',
}

export enum AdobeAnalyticsProcessStep {
    GAF_NEXT = 'next',
    GAF_QUIT = 'quit',
    GAF_BACK = 'back',
    GAF_TRIAGE = 'triage',
    GAF_ACCESS_AGREEMENT = 'access_agreement',
    GAF_ACCESS_REQUEST_SUBMITTED_INFO_ONLY = 'access_request_submitted_info_only',
    GAF_ACCESS_REQUEST_SUBMITTED_LIMITED = 'access_request_submitted_limited',
    GAF_ACCESS_AGREEMENT_INFO_ONLY = 'access_agreement_info_only',
    GAF_CONTINUE = 'continue',
    GAF_SELECT_USER = 'select_user',
    GAF_SELECT_PERSON = 'select_person',
    GAF_SELECT_ACCESS_LEVEL = 'select_access_level',
    GAF_SELECT_ACCESS_INFO = 'select_access_level_info_only',
    GAF_SELECT_ACCESS_LIMITED = 'select_access_level_limited',
    GAF_STEP_AGREE_AND_NEXT = 'agree_and_next',
    GAF_STEP_AGREE_AND_CONTINUE = 'agree_and_continue',
    GRANTEE_STEP_AGREE_AND_SUBMIT = 'agree_and_submit',
    GAF_TELL_US_ABOUT_THIS_PERSON = 'tell_us_about_this_person',
    GAF_SUBMIT = 'submit',
    GAF_GO_TO_MY_ACCOUNT = 'go_to_my_account',
    GAF_REVIEW_AND_SUBMIT_INFO_ONLY = 'review_submit_info_only',
    GAF_REVIEW_AND_SUBMIT_LIMITED_INDIVIDUAL = 'review_submit_limited_individual',
    GAF_REVIEW_AND_SUBMIT_LIMITED_JOINT = 'review_submit_limited_joint',
    GAF_REVIEW_AND_SUBMIT_FULL_AUTHORITY = 'review_submit_full_authority',
    GAF_REVIEW_AND_SUBMIT = 'review_submit',
    GAF_REVIEW_AND_SUBMIT_LIMITED_SUBMIT = 'review_submit_limited_submit',
    GAF_ADD_ANOTHER_PERSON = 'add_another_person',
    GAF_COMPLETE_OUR_FORM = 'complete_our_form',
    GRANTEE_INFO_ONLY_NOT_ON_LIST = 'info_only_grantee_not_on_list',
    GRANTEE_LIMITED_NOT_ON_LIST = 'limited_access_grantee_not_on_list',
    GRANTEE_FULFILLED_NOT_ON_LIST = 'limited_access_grantee_not_on_list_already_accepted',
    GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST = 'limited_access_grantee_on_list',
    GRANTEE_REG_QUESTIONS_GRANTEE_NOT_ON_LIST = 'limited_access_grantee_not_on_list',
    GRANTEE_NEW_PIN_INFO_ONLY = 'info_only_grantee_not_on_list_pin',
    GRANTEE_NEW_PIN_LIMITED = 'limited_access_grantee_not_on_list_pin',
    GRANTEE_WELCOME_INFO_ONLY = 'info_only_grantee_not_on_list_welcome',
    GRANTEE_WELCOME_LIMITED = 'limited_access_grantee_not_on_list_welcome',
    GRANTEE_AUTH_AGREEMENT_ON_LIST = 'agreement_grantee_on_list_limited',
    GRANTEE_AUTH_AGREEMENT_NEW = 'limited_access_grantee_not_on_list_agreement',
    GRANTEE_REG_QUESTIONS_FAQ_LINK = 'frequently_asked_questions',
    GRANTEE_NOT_WEB_REGISTERED = 'grantee_not_web-registered',
    GRANTEE_SUCCESS_ON_LIST = 'access_accepted_grantee_on_list_limited',
    GRANTEE_VIEW_MY_DASHBOARD = 'view_my_dashboard',
    RAF_SELECT_USER_TO_REMOVE = 'select_user_to_remove',
    RAF_NEXT = 'next',
    RAF_QUIT = 'quit',
    RAF_BACK = 'back',
    RAF_REVIEW_AND_SUBMIT = 'review_and_submit',
    RAF_SUBMIT = 'submit',
    RAF_CONTACT_US = 'contact_us',
}

export enum AdobeAnalyticsProcessSideStep {
    GAF_RADIO_BUTTON_INFORMATION_ONLY = 'radio_button:information_only',
    GAF_RADIO_BUTTON_LIMITED_ACCESS = 'radio_button:limited_access',
    GAF_COMPARE_ACCESS_LEVELS_DROPDOWN = 'compare_access_levels_dropdown',
    GAF_AGENT_AUTHORIZATION_FORM = 'agent_authorization_form',
    GAF_ADD_SOMEONE_ELSE = 'add_someone_else',
    GAF_CASH_PLUS_MODAL = 'info_only_cashplus_modal',
}

export enum AdobeAnalyticsErrorCode {
    DOB_FORMAT_ERROR = 'dob_format_error',
    ACC_NUMBER_ERROR = 'acc_number_error',
    EMAIL_ERROR = 'email_error',
    PHONE_NUMBER_ERROR = 'phone_number_error',
}

export interface AdobeAnalyticsProcess {
    errorCode?: AdobeAnalyticsErrorCode;
    processType?: AdobeAnalyticsProcessType;
    processStep?: AdobeAnalyticsProcessStep;
    processSideStep?: AdobeAnalyticsProcessSideStep;
    processAbandonmentType?: AdobeAnalyticsProcessStep;
}
