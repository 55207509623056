import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentUtil } from '../../../utils/content/content.util';
import { environment } from 'src/environments/environment';
import { GranteeContent } from '../../../utils/content/aem-content-types';
import { AdobeTagging } from '../../../utils/decorators/dev/adobe-launch';
import { VgnService } from '../../../services/vgn/vgn.service';
import { configData } from './grantee-web-reg-page-adobe';
import { Observable, Subscription, take } from 'rxjs';
import { AccessTypeSelection } from '../../../../common/enums/access-type-selection.enum';
import { selectGranteeAccessType } from '../../../store/selectors/grantee.selector';
import { Store } from '@ngrx/store';

@AdobeTagging(configData)
@Component({
    selector: 'zci-grantee-web-reg-page',
    templateUrl: './grantee-web-reg-page.component.html',
    styleUrls: ['./grantee-web-reg-page.component.scss'],
})
export class GranteeWebRegPageComponent implements OnInit, OnDestroy {
    readonly accessTypeSelection = AccessTypeSelection;
    constructor(
        private readonly vgnService: VgnService,
        private readonly store: Store,
    ) {}

    content: GranteeContent = ContentUtil.granteeFragmentsContent;
    environment = environment;

    readonly subs: Subscription = new Subscription();

    granteeAccessType$: Observable<AccessTypeSelection> =
        this.store.select(selectGranteeAccessType);

    ngOnInit(): void {
        this.vgnService.setMinimalNav(true);
        this.adobePageLoad();
    }

    ngOnDestroy(): void {
        this.vgnService.setMinimalNav(false);
        this.subs.unsubscribe();
    }

    private adobePageLoad(): void {}

    private adobeGetStartedInfoOnly(): void {}

    private adobeGetStartedLimited(): void {}

    onGetStartedClicked(): void {
        this.subs.add(
            this.granteeAccessType$.pipe(take(1)).subscribe((accessType) => {
                switch (accessType) {
                    case this.accessTypeSelection.INFORMATION_ONLY:
                        this.adobeGetStartedInfoOnly();
                        break;
                    case this.accessTypeSelection.LIMITED_ACCESS:
                        this.adobeGetStartedLimited();
                        break;
                }
                setTimeout(() => {
                    document.location.href = environment.GRANTEE_WEB_REG_URL;
                }, 50);
            }),
        );
    }
}
