<div class="row justify-content-lg-center container zci-margin-top c11n-space-stack-10x c11n-space--force">
    <div class="ineligible-page col-xs-12 col-sm-12 col-md-8 col-lg-6 col-xl-6 col-xxl-6">
        <div class="d-flex justify-content-center c11n-space-stack-4x">
            <c11n-icon
                custom-c11n
                class="custom-c11n-svg--fill-error custom-c11n-svg--size-large"
                [name]="'error-fill'"
                scale="larger"></c11n-icon>
        </div>
        <h1 class="c11n-text-2xl-headline c11n-space-stack-3x" data-cy="page-title">
            {{ pageContent?.pageTitle }}
        </h1>
        <p
            class="c11n-text-md-normal c11n-space-stack-6x"
            [innerHtml]="pageContent?.pageContent"></p>
        <ng-container
            *ngIf="
                isTrustOrOrgAccountType;
                then trustOrOrgAccountType;
                else neitherTrustNorOrgAccountType
            ">
        </ng-container>
        <ng-template #trustOrOrgAccountType>
            <c11n-button
                buttonType="button"
                size="medium"
                variant="primary"
                [labelText]="pageContent?.buttonText"
                data-cy="continue-to-form-button"
                [fullWidth]="isMobileView$ | async"
                [href]="agentAuthorizationFormUrl"></c11n-button>
            <div class="c11n-space-stack-5x c11n-space--force"></div>
            <button
                class="c11n-link"
                variant="primary-independent"
                data-cy="return-button"
                (click)="goBackToAccessPermissions()">
                <span class="c11n-link__content">{{ pageContent?.navLinkText }}</span>
            </button>
        </ng-template>
        <ng-template #neitherTrustNorOrgAccountType>
            <c11n-button
                buttonType="button"
                size="medium"
                variant="primary"
                [labelText]="pageContent?.buttonText"
                data-cy="return-button"
                (clickEvent)="goBackToAccessPermissions()"
                [fullWidth]="isMobileView$ | async"></c11n-button>
        </ng-template>
    </div>
</div>
