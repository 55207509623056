import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GatekeeperService } from 'src/app/services/gatekeeper/gatekeeper.service';
import { GATEKEEPER_TOGGLES } from 'src/common/constants/gatekeeper-toggles';
import { APPLICATION_ROUTES } from 'src/app/constants/application-routes';
import { LoggerService } from 'src/app/services/logger/logger.service';

@Injectable({
    providedIn: 'root',
})
export class MVP2GatekeeperGuard implements CanActivate {
    constructor(
        private router: Router,
        private gatekeeperService: GatekeeperService,
        private readonly loggerService: LoggerService
    ) { }

    canActivate(): Observable<boolean> {
        return this.gatekeeperService
            .isToggleActive(GATEKEEPER_TOGGLES.MVP_TWO_TOGGLE_ENABLED)
            .pipe(
                map((isEnabled: boolean) => {
                    if (!isEnabled) {
                        this.router.navigate([APPLICATION_ROUTES.TECHNICAL_DIFFICULTIES]);
                        return false;
                    }
                    return true;
                }),
                catchError((error) => {
                    this.loggerService.error(
                        'Error while checking MVP2 toggle status in route guard:',
                        error
                    );
                    this.router.navigate([APPLICATION_ROUTES.TECHNICAL_DIFFICULTIES]);
                    return of(false);
                })
            );
    }
}