import { environment } from 'src/environments/environment';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import configData from './add-user-triage-page-mvp-adobe';
import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { TriageMvpTileComponent } from '../../../tiles/triage-mvp-tile/triage-mvp-tile.component';
import { StepsOfAddUserToOneSelectedAccount } from '../../../pages/add-user-stepper-page/enums/steps-of-add-user-to-one-selected-account.enum';
import { ContentUtil } from '../../../../utils/content/content.util';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { Store } from '@ngrx/store';
import { updateAddUserStepperSelectedUserType } from '../../../../store/actions/add-user-stepper-flow.action';
import { UserTypeSelection } from '../../../../enums/user-type-selection.enum';
import { GatekeeperService } from 'src/app/services/gatekeeper/gatekeeper.service';
import { catchError, map, of, Subscription } from 'rxjs';
import { GATEKEEPER_TOGGLES } from 'src/common/constants/gatekeeper-toggles';
import { GrantorContent } from 'src/app/utils/content/aem-content-types';

@AdobeTagging(configData)
@Component({
    selector: 'zci-add-user-triage-page-mvp',
    templateUrl: 'add-user-triage-page-mvp.component.html',
    styleUrls: ['add-user-triage-page-mvp.component.scss'],
    standalone: true,
    imports: [AsyncPipe, CommonModule, TriageMvpTileComponent],
})
export class AddUserTriagePageMvpComponent implements OnInit, OnDestroy {
    @Input() nextStepClicked: EventEmitter<any>;
    @Input() nextStepReady: EventEmitter<any>;

    @Input() currentStep: StepsOfAddUserToOneSelectedAccount;

    readonly subs: Subscription = new Subscription();

    environment = environment;

    grantorWhoGivingAccessContent: GrantorContent['add_user']['frmcWhoGivingAccess'] = ContentUtil.grantorFragmentContent?.add_user?.frmcWhoGivingAccess;

    location: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_ACCESS_YOUR_ACCOUNT;
    isMVP2Enabled: boolean;

    constructor(
        private store: Store,
        private readonly gatekeeperService: GatekeeperService,
    ) { }

    ngOnInit(): void {
        this.subs.add(
            this.gatekeeperService
                .isToggleActive(GATEKEEPER_TOGGLES.MVP_TWO_TOGGLE_ENABLED)
                .pipe(
                    map((isEnabled: boolean) => {
                        this.isMVP2Enabled = isEnabled;
                    }),
                    catchError((error) => {
                        console.error(
                            'Error in AddUserTriagePageMvpComponent while checking MVP2 toggle status:',
                            error,
                        );
                        return of(null);
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    navigateSelectUser() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.EXISTING }),
        );
        this.nextStepReady.emit(true);
    }

    navigateAddSomeoneElse() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.NEW }),
        );
        this.nextStepReady.emit(true);
    }

    navigateToFormFromNonVGUser() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }

    navigateToFormFromOrgTrust() {
        window.open(environment.AGENT_AUTHORIZATION_FORM_URL, '_self');
    }
}
