import { Injectable } from '@angular/core';
import { FlowConfig } from '../../components/stepper/flow-config.interface';
import { AddUserTriagePageMvpComponent } from '../../components/stepper-steps/grant-access-flow-mvp/add-user-triage-page-mvp/add-user-triage-page-mvp.component';
import { ContentUtil } from '../../utils/content/content.util';
import { AdobeMethods } from '../adobe-launch/adobe-launch.methods';
import { AdobeAnalyticsProcessStep } from '../adobe-launch/adobe-launch.process';
import { AnalyticsCtaType, CtaNames } from '../adobe-launch/adobe-launch.cta';
import { GafAccessLevelMvpComponent } from '../../components/stepper-steps/grant-access-flow-mvp/gaf-access-level-mvp/gaf-access-level-mvp.component';
import { GafSelectUserStepMvpComponent } from '../../components/stepper-steps/grant-access-flow-mvp/gaf-select-user-step-mvp/gaf-select-user-step-mvp.component';
import { GafMvpAccessAgreementComponent } from '../../components/stepper-steps/grant-access-flow-mvp/gaf-mvp-access-agreement/gaf-mvp-access-agreement.component';
import { GafReviewAndSubmitStepMvpComponent } from '../../components/stepper-steps/grant-access-flow-mvp/gaf-review-and-submit-step-mvp/gaf-review-and-submit-step-mvp.component';
import { FakeStepOneComponent } from '../../components/stepper/test-components/fake-step-one/fake-step-one.component';
import { FakeStepTwoComponent } from '../../components/stepper/test-components/fake-step-two/fake-step-two.component';
import { FakeStepThreeComponent } from '../../components/stepper/test-components/fake-step-three/fake-step-three.component';
import {
    ExistingGranteeRegulatoryQuestionsComponent,
    NewGranteeRegulatoryQuestionsComponent,
} from '../../components/stepper-steps/grantee-flow/grantee-regulatory-questions/grantee-regulatory-questions.component';
import {
    ExistingGranteeAuthorizationAgreementComponent,
    NewGranteeAuthorizationAgreementComponent,
} from '../../components/stepper-steps/grantee-flow/grantee-authorization-agreement/grantee-authorization-agreement.component';
import {
    ExistingGranteeSuccessPageComponent,
    NewGranteeInfoOnlySuccessPageComponent,
    NewGranteeLimitedSuccessPageComponent,
} from '../../components/stepper-steps/grantee-flow/grantee-success-page/grantee-success-page.component';
import { GranteePinInputComponent } from '../../components/stepper-steps/grantee-flow/grantee-pin-input/grantee-pin-input.component';

export type FlowKey =
    | 'GAF'
    | 'RAF'
    | 'EXISTING_GRANTEE_LIMITED'
    | 'NEW_GRANTEE_LIMITED'
    | 'NEW_GRANTEE_INFO_ONLY'
    | 'TEST';

@Injectable({
    providedIn: 'root',
})
export class FlowConfigService {
    getFlowConfig(flowKey: FlowKey): FlowConfig {
        switch (flowKey) {
            case 'GAF':
                return this.gaf();
            case 'RAF':
                return this.raf();
            case 'EXISTING_GRANTEE_LIMITED':
                return this.existingGranteeLimited();
            case 'NEW_GRANTEE_INFO_ONLY':
                return this.newGranteeInfoOnly();
            case 'NEW_GRANTEE_LIMITED':
                return this.newGranteeLimited();
            case 'TEST':
                return this.test();
        }
    }

    gaf(): FlowConfig {
        return {
            flowCaption: ContentUtil.grantorFragmentContent.add_user.lblGiveAccess,
            buttons: {
                contactUs: true,
                quit: true,
            },
            steps: [
                {
                    component: AddUserTriagePageMvpComponent,
                    inputs: {},
                    primaryButton: '',
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,

                    title: ContentUtil.grantorFragmentContent.add_user.lblAddUserSteps
                        .lblGranteeType,
                    adobeLocation: 'triage',
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location: AdobeAnalyticsProcessStep.GAF_TRIAGE,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.BUTTON,
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    component: GafAccessLevelMvpComponent,
                    inputs: {},
                    primaryButton: '',
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: ContentUtil.grantorFragmentContent.add_user.lblAddUserSteps
                        .lblAccessLevel,
                    adobeLocation: AdobeAnalyticsProcessStep.GAF_SELECT_ACCESS_LEVEL,
                },
                {
                    component: GafSelectUserStepMvpComponent,
                    inputs: {},
                    primaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navContinue,
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: ContentUtil.grantorFragmentContent.add_user.lblAddUserSteps.lblPerson,
                    adobeLocation: AdobeAnalyticsProcessStep.GAF_SELECT_PERSON,
                },
                {
                    component: GafMvpAccessAgreementComponent,
                    inputs: {},
                    primaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navAgreeContinue,
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: ContentUtil.grantorFragmentContent.add_user.lblAddUserSteps.lblAgreement,
                    adobeLocation: AdobeAnalyticsProcessStep.GAF_ACCESS_AGREEMENT,
                },
                {
                    component: GafReviewAndSubmitStepMvpComponent,
                    inputs: {},
                    primaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navSubmit,
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: ContentUtil.grantorFragmentContent.add_user.lblAddUserSteps
                        .lblReviewSubmitStep,
                    adobeLocation: AdobeAnalyticsProcessStep.GAF_REVIEW_AND_SUBMIT,
                },
            ],
        };
    }

    raf(): FlowConfig {
        return {
            flowCaption: '',
            steps: [
                {
                    component: FakeStepOneComponent,
                    inputs: {},
                    primaryButton: 'next',
                    secondaryButton: 'back',
                    title: 'Fake component one',
                    adobeLocation: '',
                },
                {
                    component: FakeStepTwoComponent,
                    inputs: {},
                    primaryButton: 'submit',
                    secondaryButton: 'back',
                    title: 'Fake component two',
                    adobeLocation: '',
                },
            ],
        };
    }

    test(): FlowConfig {
        return {
            flowCaption: 'test',
            steps: [
                {
                    component: FakeStepThreeComponent,
                    inputs: {},
                    primaryButton: 'next',
                    secondaryButton: '',
                    title: 'Fake component one',
                    adobeLocation: 'step1',
                },
                {
                    component: FakeStepThreeComponent,
                    inputs: {},
                    primaryButton: 'submit',
                    secondaryButton: 'back',
                    title: 'Fake component two',
                    adobeLocation: null,
                },
            ],
        };
    }

    existingGranteeLimited(): FlowConfig {
        return {
            isLastStepStandalone: true,
            flowCaption: ContentUtil.granteeFragmentsContent.lblAcceptLimitedAccess,
            steps: [
                {
                    component: ExistingGranteeRegulatoryQuestionsComponent,
                    inputs: {},
                    primaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navContinue,
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: '',
                    adobeLocation: AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST,
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location:
                                            AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.LINK,
                                        fireTrackLink: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    component: ExistingGranteeAuthorizationAgreementComponent,
                    inputs: {},
                    primaryButton:
                        ContentUtil.granteeFragmentsContent.trmAuthorizationAgreement
                            .navAgreeSubmit,
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: '',
                    adobeLocation: AdobeAnalyticsProcessStep.GRANTEE_AUTH_AGREEMENT_ON_LIST,
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location:
                                            AdobeAnalyticsProcessStep.GRANTEE_AUTH_AGREEMENT_ON_LIST,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.LINK,
                                        fireTrackLink: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    component: ExistingGranteeSuccessPageComponent,
                    inputs: {},
                    primaryButton: '', // TODO: Replace this hardcoded value with AEM content fragment when it's available
                    secondaryButton: '',
                    title: '',
                    adobeLocation: AdobeAnalyticsProcessStep.GRANTEE_SUCCESS_ON_LIST,
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location: AdobeAnalyticsProcessStep.GRANTEE_SUCCESS_ON_LIST,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.LINK,
                                        fireTrackLink: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
            ],
            buttons: {
                contactUs: false,
                quit: true,
            },
        };
    }

    newGranteeInfoOnly(): FlowConfig {
        return {
            isLastStepStandalone: true,
            flowCaption: ContentUtil.granteeFragmentsContent.lblAcceptInformationOnlyAccess,
            steps: [
                {
                    component: GranteePinInputComponent,
                    inputs: { isSubmitStep: true },
                    primaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navSubmit,
                    secondaryButton: '',
                    title: ContentUtil.granteeFragmentsContent.lblGranteeFlowProgressIndicatorSteps
                        .lblUniquePin,
                    adobeLocation: AdobeAnalyticsProcessStep.GRANTEE_NEW_PIN_INFO_ONLY,
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location:
                                            AdobeAnalyticsProcessStep.GRANTEE_INFO_ONLY_NOT_ON_LIST,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.BUTTON,
                                        fireTrackLink: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    component: NewGranteeInfoOnlySuccessPageComponent,
                    inputs: {},
                    primaryButton: '',
                    secondaryButton: '',
                    title: '',
                    adobeLocation: null,
                },
            ],
            buttons: {
                contactUs: false,
                quit: true,
            },
        };
    }

    newGranteeLimited(): FlowConfig {
        return {
            isLastStepStandalone: true,
            flowCaption: ContentUtil.granteeFragmentsContent.lblAcceptLimitedAccess,
            steps: [
                //TODO add logic to conditionally choose button label text based on access type
                {
                    component: GranteePinInputComponent,
                    inputs: {},
                    primaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navContinue,
                    secondaryButton: '',
                    title: ContentUtil.granteeFragmentsContent.lblGranteeFlowProgressIndicatorSteps
                        .lblUniquePin,
                    adobeLocation: AdobeAnalyticsProcessStep.GRANTEE_NEW_PIN_LIMITED,
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location: AdobeAnalyticsProcessStep.GRANTEE_NEW_PIN_LIMITED,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.BUTTON,
                                        fireTrackLink: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    component: NewGranteeRegulatoryQuestionsComponent,
                    inputs: {},
                    primaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navContinue,
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: ContentUtil.granteeFragmentsContent.lblGranteeFlowProgressIndicatorSteps
                        .lblRegulatoryQuestions,
                    adobeLocation:
                        AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_NOT_ON_LIST,
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location:
                                            AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_NOT_ON_LIST,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.BUTTON,
                                        fireTrackLink: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    component: NewGranteeAuthorizationAgreementComponent,
                    inputs: {},
                    primaryButton:
                        ContentUtil.granteeFragmentsContent.trmAuthorizationAgreement
                            .navAgreeSubmit,
                    secondaryButton: ContentUtil.grantorFragmentContent.stepperNavs.navBack,
                    title: ContentUtil.granteeFragmentsContent.lblGranteeFlowProgressIndicatorSteps
                        .lblAuthorizationAgreement,
                    adobeLocation: AdobeAnalyticsProcessStep.GRANTEE_AUTH_AGREEMENT_NEW,
                    customAdobeTags: {
                        onQuit: [
                            {
                                method: AdobeMethods.TRACK_ACTION,
                                data: [
                                    {
                                        location:
                                            AdobeAnalyticsProcessStep.GRANTEE_AUTH_AGREEMENT_NEW,
                                        ctaName: CtaNames.QUIT,
                                        ctaType: AnalyticsCtaType.BUTTON,
                                        fireTrackLink: true,
                                    },
                                ],
                            },
                        ],
                    },
                },
                {
                    component: NewGranteeLimitedSuccessPageComponent,
                    inputs: {},
                    primaryButton: '',
                    secondaryButton: '',
                    title: 'Success page',
                    adobeLocation: null,
                },
            ],
            buttons: {
                contactUs: false,
                quit: true,
            },
        };
    }
}
