import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentUtil } from '../../../../utils/content/content.util';
import { Observable, Subscription } from 'rxjs';
import { BreakpointObserverService } from '../../../../services/breakpoint-observer/breakpoint-observer.service';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';
import { selectGranteeAccessType } from '../../../../store/selectors/grantee.selector';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs/operators';
import { VgnService } from 'src/app/services/vgn/vgn.service';
import { configData } from 'src/app/components/pages/grantee-web-reg-page/grantee-web-reg-page-adobe';
import { AdobeTagging } from 'src/app/utils/decorators/dev/adobe-launch';

@AdobeTagging(configData)
@Component({
    selector: 'zci-grantee-welcome-page',
    templateUrl: './grantee-welcome-page.component.html',
    styleUrls: ['./grantee-welcome-page.component.scss']
})
export class GranteeWelcomePageComponent implements OnInit, OnDestroy {

    readonly accessTypeSelection = AccessTypeSelection;

    isMobileView$: Observable<boolean> = this.breakpointObserverService.isMobileView$;

    readonly subs: Subscription = new Subscription();

    granteeAccessType$: Observable<AccessTypeSelection> = this.store.select(selectGranteeAccessType);

    accessType: AccessTypeSelection;

    adobeTrackActionLocationInfoOnly: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_WELCOME_PAGE_INFO_ONLY;

    adobeTrackActionLocationLimited: CtaCallUsLinkLocations = CtaCallUsLinkLocations.ZCI_WELCOME_PAGE_LIMITED;

    adobeLocationData: CtaCallUsLinkLocations;

    environment = environment;

    welcomePageContent = ContentUtil.granteeFragmentsContent; // TODO Map Content with AEM content

    constructor(
        private readonly breakpointObserverService: BreakpointObserverService,
        private readonly store: Store,
        private readonly vgnService: VgnService,
    ) { }

    ngOnInit(): void {
        this.subs.add(this.getAccessType());
        this.vgnService.setMinimalNav(true);
        this.setAdobeLocationData();

    }

    setAdobeLocationData(): void {
        this.adobeLocationData =
            this.accessType === this.accessTypeSelection.INFORMATION_ONLY
                ? this.adobeTrackActionLocationInfoOnly
                : this.adobeTrackActionLocationLimited;
    }

    public getAccessType(): Subscription {
        return this.granteeAccessType$.pipe(take(1)).subscribe((accessType) => {
            this.accessType = accessType;
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onGetStartedClicked(): void {
        // TODO: Replace route which will be used to navigate to the next page.
        window.open('', '_self');
    }
}
