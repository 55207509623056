<div class="container zci-margin-top c11n-space-stack-10x c11n-space--force">
    <div
        *ngIf="accessType === accessTypeSelection.INFORMATION_ONLY"
        class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto"
    >
        <h1 class="c11n-text-2xl-headline c11n-space-stack-2x" data-cy="welcome-page-heading">
            {{ welcomePageContent.acceptInfoOnlyRequestChecklist.heading }}
        </h1>
        <p
            class="c11n-text-md c11n-space-stack-4x"
            data-cy="welcome-page-sub-heading"
            [innerHTML]="welcomePageContent.acceptInfoOnlyRequestChecklist.text1"
        ></p>

        <ol
            role="list"
            class="c11n-list c11n-list--circles c11n-list--turquoise c11n-space-inline-left-3x c11n-space-stack-6x"
        >
            <li class="c11n-space-stack-4x" data-cy="welcome-page-steps">
                <p class="c11n-text-lg-headline c11n-space-stack-1x" data-cy="step-title">
                    {{ welcomePageContent.acceptInfoOnlyRequestChecklist.setupOnlineAccessToVgListItem.heading }}
                </p>
                <p
                    class="description"
                    data-cy="step-description"
                    [innerHTML]="welcomePageContent.acceptInfoOnlyRequestChecklist.setupOnlineAccessToVgListItem.text1"
                ></p>
            </li>
            <li class="c11n-space-stack-4x" data-cy="welcome-page-steps">
                <p class="c11n-text-lg-headline c11n-space-stack-1x" data-cy="step-title">
                    {{ welcomePageContent.acceptInfoOnlyRequestChecklist.infoOnlyLoginWithCredentialsListItem }}
                </p>
               
            </li>
            <li class="c11n-space-stack-4x" data-cy="welcome-page-steps">
                <p class="c11n-text-lg-headline c11n-space-stack-1x" data-cy="step-title">
                    {{ welcomePageContent.acceptInfoOnlyRequestChecklist.infoOnlyEnterUniquePinListItem.heading }}
                </p>
                <p
                    class="description"
                    [innerHTML]="welcomePageContent.acceptInfoOnlyRequestChecklist.infoOnlyEnterUniquePinListItem.text1"
                    data-cy="step-description"
                ></p>
            </li>
        </ol>

        <div class="button-wrapper">
            <c11n-button
                buttonType="button"
                size="medium"
                variant="primary"
                [labelText]="welcomePageContent.acceptInfoOnlyRequestChecklist.navGetStarted"
                iconPosition="leading"
                data-cy="get-started-button"
                [fullWidth]="isMobileView$ | async"
                (clickEvent)="onGetStartedClicked()"
                targetWindow="_self"
            ></c11n-button>
            <div class="d-flex">
                <svg
                    width="42"
                    height="41"
                    viewBox="0 0 42 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    aria-label="clock icon"
                    alt=""
                >
                    <path
                        d="M24.1111 0.0104907L24.1065 1.39739L22.7643 1.39529L22.7597 2.89548L19.4006 2.89129L19.4052 1.38899L17.9482 1.38689L17.9528 0L24.1111 0.0104907Z"
                        fill="black"
                    />
                    <path
                        d="M21.0293 40.0534C32.0912 40.0534 41.0586 31.6271 41.0586 21.2327C41.0586 10.8384 32.0912 2.41211 21.0293 2.41211C9.96741 2.41211 1 10.8384 1 21.2327C1 31.6271 9.96741 40.0534 21.0293 40.0534Z"
                        fill="#C1202B"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M21.0297 38.1226C30.957 38.1226 39.0047 30.4525 39.0047 20.991C39.0047 11.5295 30.957 3.85938 21.0297 3.85938C11.1024 3.85938 3.05469 11.5295 3.05469 20.991C3.05469 30.4525 11.1024 38.1226 21.0297 38.1226Z"
                        fill="white"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path d="M21.0297 12.0645V21.1547L13.3262 28.4722" fill="white" />
                    <path
                        d="M21.0297 12.0645V21.1547L13.3262 28.4722"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path d="M21.0295 4.82617V9.1694" stroke="black" stroke-miterlimit="10" />
                    <path d="M21.0293 33.2988V37.1595" stroke="black" stroke-miterlimit="10" />
                    <path d="M37.9777 21.2344H33.8691" stroke="black" stroke-miterlimit="10" />
                    <path d="M8.1906 21.2344H4.08203" stroke="black" stroke-miterlimit="10" />
                    <path d="M37.9777 21.2344H33.8691" stroke="black" stroke-miterlimit="10" />
                    <path d="M8.1906 21.2344H4.08203" stroke="black" stroke-miterlimit="10" />
                    <path
                        d="M33.3558 9.65234L30.2744 12.5478"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M11.7846 29.4375L8.70312 32.333"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M33.3558 32.333L30.2744 29.4375"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M11.7846 12.5478L8.70312 9.65234"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                </svg>
                <div class="estimated-time-text">
                    <span class="c11n-text-xs" data-cy="clock-title">{{
                        welcomePageContent.acceptInfoOnlyRequestChecklist.lblEstimatedTimeToComplete
                    }}</span>
                    <span class="c11n-text-md-bold" data-cy="clock-minutes">{{
                        welcomePageContent.acceptInfoOnlyRequestChecklist.lblMinutes.replace('${minutes}', '15')
                    }}</span>
                </div>
            </div>
        </div>
    </div>
    <div
        *ngIf="accessType === accessTypeSelection.LIMITED_ACCESS"
        class="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto"
    >
        <h1 class="c11n-text-2xl-headline c11n-space-stack-2x" data-cy="welcome-page-heading">
            {{ welcomePageContent.acceptLimitedAuthorityRequestChecklist.heading}}
        </h1>
        <p
            class="c11n-text-md c11n-space-stack-4x"
            data-cy="welcome-page-sub-heading"
            [innerHTML]="welcomePageContent.acceptLimitedAuthorityRequestChecklist.text1"
        ></p>

        <ol
            role="list"
            class="c11n-list c11n-list--circles c11n-list--turquoise c11n-space-inline-left-3x c11n-space-stack-6x"
        >
            <li class="c11n-space-stack-4x" data-cy="welcome-page-steps">
                <p class="c11n-text-lg-headline c11n-space-stack-1x" data-cy="step-title">
                    {{ welcomePageContent.acceptLimitedAuthorityRequestChecklist.setupOnlineAccessToVgListItem.heading }}
                </p>
                <p
                    class="description"
                    data-cy="step-description"
                    [innerHTML]="welcomePageContent.acceptLimitedAuthorityRequestChecklist.setupOnlineAccessToVgListItem.text1"
                ></p>
            </li>
            <li class="c11n-space-stack-4x" data-cy="welcome-page-steps">
                <p class="c11n-text-lg-headline c11n-space-stack-1x" data-cy="step-title">
                    {{ welcomePageContent.acceptLimitedAuthorityRequestChecklist.limitedAuthorityLoginEnterPinListItem.heading }}
                </p>
                <p
                    class="description"
                    data-cy="step-description"
                    [innerHTML]="welcomePageContent.acceptLimitedAuthorityRequestChecklist.limitedAuthorityLoginEnterPinListItem.text1"
                ></p>
            </li>
            <li class="c11n-space-stack-4x" data-cy="welcome-page-steps">
                <p class="c11n-text-lg-headline c11n-space-stack-1x" data-cy="step-title">
                    {{ welcomePageContent.acceptLimitedAuthorityRequestChecklist.limitedAuthorityAcceptAccessRequestListItem.heading }}
                </p>
                <p
                    class="description"
                    [innerHTML]="welcomePageContent.acceptLimitedAuthorityRequestChecklist.limitedAuthorityAcceptAccessRequestListItem.text1"
                    data-cy="step-description"
                ></p>
            </li>
        </ol>

        <div class="button-wrapper">
            <c11n-button
                buttonType="button"
                size="medium"
                variant="primary"
                [labelText]="welcomePageContent.acceptLimitedAuthorityRequestChecklist.navGetStarted"
                iconPosition="leading"
                data-cy="get-started-button"
                [fullWidth]="isMobileView$ | async"
                (clickEvent)="onGetStartedClicked()"
                targetWindow="_self"
            ></c11n-button>
            <div class="d-flex">
                <svg
                    width="42"
                    height="41"
                    viewBox="0 0 42 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    role="img"
                    aria-label="clock icon"
                    alt=""
                >
                    <path
                        d="M24.1111 0.0104907L24.1065 1.39739L22.7643 1.39529L22.7597 2.89548L19.4006 2.89129L19.4052 1.38899L17.9482 1.38689L17.9528 0L24.1111 0.0104907Z"
                        fill="black"
                    />
                    <path
                        d="M21.0293 40.0534C32.0912 40.0534 41.0586 31.6271 41.0586 21.2327C41.0586 10.8384 32.0912 2.41211 21.0293 2.41211C9.96741 2.41211 1 10.8384 1 21.2327C1 31.6271 9.96741 40.0534 21.0293 40.0534Z"
                        fill="#C1202B"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M21.0297 38.1226C30.957 38.1226 39.0047 30.4525 39.0047 20.991C39.0047 11.5295 30.957 3.85938 21.0297 3.85938C11.1024 3.85938 3.05469 11.5295 3.05469 20.991C3.05469 30.4525 11.1024 38.1226 21.0297 38.1226Z"
                        fill="white"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path d="M21.0297 12.0645V21.1547L13.3262 28.4722" fill="white" />
                    <path
                        d="M21.0297 12.0645V21.1547L13.3262 28.4722"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path d="M21.0295 4.82617V9.1694" stroke="black" stroke-miterlimit="10" />
                    <path d="M21.0293 33.2988V37.1595" stroke="black" stroke-miterlimit="10" />
                    <path d="M37.9777 21.2344H33.8691" stroke="black" stroke-miterlimit="10" />
                    <path d="M8.1906 21.2344H4.08203" stroke="black" stroke-miterlimit="10" />
                    <path d="M37.9777 21.2344H33.8691" stroke="black" stroke-miterlimit="10" />
                    <path d="M8.1906 21.2344H4.08203" stroke="black" stroke-miterlimit="10" />
                    <path
                        d="M33.3558 9.65234L30.2744 12.5478"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M11.7846 29.4375L8.70312 32.333"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M33.3558 32.333L30.2744 29.4375"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                    <path
                        d="M11.7846 12.5478L8.70312 9.65234"
                        stroke="black"
                        stroke-miterlimit="10"
                    />
                </svg>
                <div class="estimated-time-text">
                    <span class="c11n-text-xs" data-cy="clock-title">{{
                        welcomePageContent.acceptLimitedAuthorityRequestChecklist.lblEstimatedTimeToComplete
                    }}</span>
                    <span class="c11n-text-md-bold" data-cy="clock-minutes">{{
                        welcomePageContent.acceptLimitedAuthorityRequestChecklist.lblMinutes.replace('${minutes}', '15')
                    }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
