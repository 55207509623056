import { createAction, props } from '@ngrx/store';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';

export const updateAccessTypeInGranteeFlow = createAction(
    '[Access level] Update access level for received token',
    (accessType: AccessTypeSelection) => ({ accessType }),
);

export const setGranteeRegulatoryQuestions = createAction(
    '[Regulatory questions] Set regulatory question based on client selection',
    props<{ isVanguardEmployee: boolean; isAssociatedPerson: boolean; isControlPerson: boolean }>(),
);
