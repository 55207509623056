import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { CtaNames } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeAnalyticsProcessStep } from '../../../../services/adobe-launch/adobe-launch.process';

export const configData = {
    adobeInfoOnlyPageLoad: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GRANTEE_PIN_PAGE_INFO_ONLY_NOT_ON_LIST],
        },
    ],
    adobeLimitedOnlyPageLoad: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GRANTEE_PIN_PAGE_LIMITED_NOT_ON_LIST],
        },
    ],

    adobeNextStepLoadInfoOnly: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GRANTEE_NEW_PIN_INFO_ONLY,
                    ctaName: CtaNames.CONTINUE,
                    ctaType: 'button',
                },
            ],
        },
    ],

    adobeNextStepLoadLimited: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GRANTEE_NEW_PIN_LIMITED,
                    ctaName: CtaNames.CONTINUE,
                    ctaType: 'button',
                },
            ],
        },
    ],
}
