export enum AnalyticsCtaType {
    LINK = 'link',
    RADIO_BUTTON = 'radio button',
    BUTTON = 'button',
}

export interface AdobeAnalyticsCallToAction {
    location: string;
    ctaName: string;
    ctaType: AnalyticsCtaType;
}

export enum CtaNames {
    ALREADY_HAS_ACCESS_PERMISSIONS = 'already_has_access_permissions',
    OWN_VANGUARD_ACCOUNT = 'own_vanguard_account',
    WITHOUT_VANGUARD_ACCOUNT = 'without_vanguard_account',
    ORGANIZATION_OR_TRUST = 'organization_or_trust',
    CONTACT_US = 'contact_us',
    QUIT = 'quit',
    FULL_AUTHORITY = 'full_authority',
    CONTINUE = 'continue',
    COMPLETE_OUR_FORM = 'complete_our_form',
    GET_STARTED = 'get started',
    GET_STARTED_INFO_ONLY = 'get_started_info_only',
    GET_STARTED_LIMITED = 'get_started_limited',
    RETURN_TO_ACCESS_PERMISSIONS = 'return_to_access_permissions',
    GO_TO_MY_DASHBOARD = 'go_to_my_dashboard',
    SUBMIT = 'submit',
}

export enum CtaCallUsLinkLocations {
    ZCI_ACCESS_AGREEMENT_LIMITED = 'access_agreement_limited',
    ZCI_ACCESS_AGREEMENT_INFO_ONLY = 'access_agreement_info_only',
    ZCI_REVIEW_STEP_INFO_ONLY = 'review_and_submit_info_only',
    ZCI_REVIEW_STEP_LIMITED_ACCESS = 'review_and_submit_limited_access',
    ZCI_SELECT_ACCESS_STEP = 'select_access_level',
    ZCI_ADD_SOMEONE_ELSE = 'tell_us_about_this_person',
    ZCI_SELECT_EXISTING_USER = 'select_who_you_like_to_give_access',
    ZCI_INFORMATION_ONLY_ACCESS = 'information_only_access',
    ZCI_ACCESS_REQUEST_SUBMITTED_INFO_ONLY = 'access_request_submitted_info_only',
    ZCI_ACCESS_REQUEST_SUBMITTED_LIMITED = 'access_request_submitted_limited',
    ZCI_WELCOME_PAGE_INFO_ONLY = 'welcome_info_only',
    ZCI_WELCOME_PAGE_LIMITED = 'welcome_limited',
    ZCI_ACCESS_YOUR_ACCOUNT = 'access_your_account',
    ZCI_TRIAGE_PAGE = 'triage',
    ZCI_SELECT_PERSON_STEP = 'select_person',
    ZCI_GRANTEE_NOT_WEB_REGISTERED = 'Grantee not Web-Registered',
}
