<div class="container zci-margin-top c11n-space-stack-10x c11n-space--force">
    <div class="col-xs-12 col-sm-12 col-md-8 col-xl-6 col-xxl-5 mx-auto">
        <svg
            class="c11n-space-stack-3x"
            width="50px"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48">
            <g transform="matrix(2,0,0,2,0,0)">
                <path
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16 5.5C16 5.20333 16.088 4.91332 16.2528 4.66665C16.4176 4.41997 16.6519 4.22771 16.926 4.11418C17.2001 4.00065 17.5017 3.97094 17.7926 4.02882C18.0836 4.0867 18.3509 4.22956 18.5607 4.43934C18.7704 4.64912 18.9133 4.91639 18.9712 5.20737C19.0291 5.49834 18.9993 5.79994 18.8858 6.07403C18.7723 6.34812 18.58 6.58238 18.3334 6.74721C18.0867 6.91203 17.7967 7 17.5 7"></path>
                <path
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.433 0.500004C16.352 0.498763 15.2903 0.786763 14.3581 1.33413C13.4259 1.8815 12.6571 2.66831 12.1315 3.61295C11.6059 4.55758 11.3425 5.62564 11.3688 6.70635C11.3951 7.78705 11.7101 8.84104 12.281 9.759L10.5 13.5L14.241 11.718C15.0385 12.2124 15.9398 12.515 16.8741 12.6019C17.8083 12.6889 18.75 12.5578 19.6251 12.2191C20.5001 11.8804 21.2846 11.3433 21.9168 10.65C22.549 9.95664 23.0117 9.12607 23.2685 8.22359C23.5253 7.32112 23.5692 6.37137 23.3967 5.44907C23.2242 4.52677 22.84 3.65709 22.2743 2.90845C21.7087 2.15981 20.9771 1.55264 20.137 1.13472C19.2969 0.716803 18.3713 0.499531 17.433 0.500004V0.500004Z"></path>
                <path
                    stroke="#000000"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M8.61201 12.813C7.97501 12.127 7.36301 11.427 6.78401 10.72L7.49201 10.013C7.86695 9.63794 8.07759 9.12933 8.07759 8.599C8.07759 8.06867 7.86695 7.56005 7.49201 7.185L5.36901 5.064C4.99396 4.68906 4.48534 4.47842 3.95501 4.47842C3.42468 4.47842 2.91607 4.68906 2.54101 5.064L1.37801 6.228C0.889444 6.71656 0.584889 7.35906 0.515994 8.04655C0.447099 8.73404 0.618105 9.4242 1.00001 10C4.42436 15.1558 8.84418 19.5756 14 23C14.5765 23.3812 15.2669 23.5517 15.9545 23.4826C16.6421 23.4136 17.2849 23.1092 17.774 22.621L18.938 21.459C19.313 21.0839 19.5236 20.5753 19.5236 20.045C19.5236 19.5147 19.313 19.0061 18.938 18.631L16.817 16.508C16.6313 16.3222 16.4108 16.1748 16.1681 16.0742C15.9254 15.9737 15.6652 15.9219 15.4025 15.9219C15.1398 15.9219 14.8797 15.9737 14.637 16.0742C14.3943 16.1748 14.1737 16.3222 13.988 16.508L13.281 17.215C12.575 16.636 11.874 16.024 11.187 15.387"></path>
                <path
                    stroke="#000000"
                    d="M17.5 9C17.3619 9 17.25 8.88807 17.25 8.75C17.25 8.61193 17.3619 8.5 17.5 8.5"></path>
                <path
                    stroke="#000000"
                    d="M17.5 9C17.6381 9 17.75 8.88807 17.75 8.75C17.75 8.61193 17.6381 8.5 17.5 8.5"></path>
            </g>
        </svg>

        <h1 class="c11n-text-xl-headline c11n-space-stack-3x" data-cy="page-title">
            {{ content.add_user.errTechnicalDifficultiesCall.heading }}
        </h1>        
        <p
            class="c11n-space-stack-6x error-text"
            [innerHtml]="content.add_user.errTechnicalDifficultiesCall.text1"></p>
        <a
            c11n-link
            [href]="environment.ACCOUNT_SETTINGS_URL"
            variant="primary-reinforced"
            (click)="trackReturnToProfileAndAccountSettingsLink()"
        >{{ content.add_user.errTechnicalDifficultiesCall.navReturnProfileAccountSettings }}</a
        >
    </div>
</div>
