import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsProcessStep } from '../../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeGranteeFlowType } from '../grantee-flows.interface';

export function getAdobeConfigData(
    granteeFlow: AdobeGranteeFlowType.EXISTING_LIMITED | AdobeGranteeFlowType.NEW_LIMITED,
) {
    let pageName: string;
    let ctaLocation: string;

    if (granteeFlow === AdobeGranteeFlowType.NEW_LIMITED) {
        pageName = AdobePageName.GRANTEE_REG_QUESTIONS_GRANTEE_NEW;
        ctaLocation = AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_NOT_ON_LIST;
    } else {
        pageName = AdobePageName.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST;
        ctaLocation = AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST;
    }

    return {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [pageName],
            },
        ],
        onNextStepLoad: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: [ctaLocation],
                        ctaName: AdobeAnalyticsProcessStep.GAF_CONTINUE,
                        ctaType: AnalyticsCtaType.BUTTON,
                    },
                ],
            },
        ],
        openFaqDrawer: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: [ctaLocation],
                        ctaName: AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_FAQ_LINK,
                        ctaType: AnalyticsCtaType.LINK,
                        fireTrackLink: true,
                    },
                ],
            },
        ],
    };
}
