import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { ContentUtil } from '../../../../utils/content/content.util';
import { catchError, map, Subscription, tap, Observable } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { CONSTANTS } from '../../../../../common/constants/constants';
import { PinValidationService } from '../../../../services/pin-validation/pin-validation.service';
import { PERMISSION_REQUEST_ERRORS } from '../../../../enums/permission_request_error.enum';
import { ActivatedRoute } from '@angular/router';
import { TOKEN_PARAM } from '../../../../constants/application-routes';
import { ErrorService } from '../../../../services/generic-error/error.service';
import { ErrorPage, errorPageMap } from '../../../../services/generic-error/error-page-utils';
import { AccessPermissionsService } from '../../../../services/access-permissions/access-permissions.service';
import { Store } from '@ngrx/store';
import { selectGranteeAccessType } from '../../../../store/selectors/grantee.selector';
import { AccessTypeSelection } from '../../../../../common/enums/access-type-selection.enum';
import { AdobeTagging } from '../../../../utils/decorators/dev/adobe-launch';
import { configData } from './grantee-pin-input-adobe';

@AdobeTagging(configData)
@Component({
    selector: 'zci-grantee-pin-input',
    templateUrl: 'grantee-pin-input.component.html',
    styleUrls: ['grantee-pin-input.component.scss'],
})
export class GranteePinInputComponent implements OnInit, OnDestroy {
    readonly accessTypeSelection = AccessTypeSelection;

    @Input() nextStepClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() nextStepReady: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() isSubmitStep: boolean;

    granteePinContent = ContentUtil.granteeFragmentsContent.frmcGranteeFlow.frmcUniquePin;

    pinFormControlName: string = 'pin';

    pinForm = new FormGroup({
        [this.pinFormControlName]: new FormControl(null, [
            Validators.pattern(CONSTANTS.UNIQUE_PIN_VALIDATION_PATTERN),
            Validators.required,
        ]),
    });

    pinValue: string;
    tokenValue: string;

    readonly subs: Subscription = new Subscription();

    granteeAccessType$: Observable<AccessTypeSelection> =
        this.store.select(selectGranteeAccessType);

    constructor(
        private pinValidationService: PinValidationService,
        private route: ActivatedRoute,
        private errorService: ErrorService,
        private accessPermissionsService: AccessPermissionsService,
        private store: Store,
    ) { }

    ngOnInit(): void {
        this.subs.add(this.nextStepClicked.subscribe(this.onNextStepLoad.bind(this)));
        this.route.paramMap
            .pipe(
                map((params) => {
                    this.tokenValue = params.get(TOKEN_PARAM);
                }),
            )
            .subscribe();

        this.subs.add(
            this.granteeAccessType$.subscribe((accessType) => {
                switch (accessType) {
                    case this.accessTypeSelection.INFORMATION_ONLY:
                        this.adobeInfoOnlyPageLoad();
                        break;
                    case this.accessTypeSelection.LIMITED_ACCESS:
                        this.adobeLimitedOnlyPageLoad();
                        break;
                }
            }),
        );
    }

    private adobeInfoOnlyPageLoad(): void { }

    private adobeLimitedOnlyPageLoad(): void { }

    private adobeNextStepLoadInfoOnly(): void { }

    private adobeNextStepLoadLimited(): void { }

    private handleNextStepLoadAdobe(): void {
        this.granteeAccessType$.subscribe((accessType) => {
            switch (accessType) {
                case this.accessTypeSelection.INFORMATION_ONLY:
                    this.adobeNextStepLoadInfoOnly();
                    break;
                case this.accessTypeSelection.LIMITED_ACCESS:
                    this.adobeNextStepLoadLimited();
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    private onNextStepLoad(): void {
        this.pinValue = this.pinForm.get(this.pinFormControlName).value;
        this.pinForm.markAllAsTouched();

        if (!this.pinForm.valid) {
            this.nextStepReady.emit(false);
        } else {
            this.pinValidationService
                .validatePin(this.pinValue, this.tokenValue)
                .subscribe((response) => {
                    if (response.error === PERMISSION_REQUEST_ERRORS.PIN_IS_INCORRECT) {
                        this.pinFormControl.setErrors({ pattern: true });
                        this.nextStepReady.emit(false);
                        return;
                    }

                    if (Object.hasOwn(errorPageMap, response.error)) {
                        this.errorService.showError(errorPageMap[response.error]);
                        this.nextStepReady.emit(false);
                        return;
                    }

                    if (this.isSubmitStep) {
                        this.accessPermissionsService
                            .acceptPermission(this.tokenValue)
                            .pipe(
                                tap((response) => {
                                    this.nextStepReady.emit(!response.error);

                                    if (response.error) {
                                        this.errorService.showError(errorPageMap[response.error]);
                                    } else this.handleNextStepLoadAdobe();
                                }),
                                catchError(() => {
                                    this.errorService.showError(ErrorPage.TECH_DIFF);
                                    return Promise.reject();
                                }),
                            )
                            .subscribe();
                    } else {
                        this.handleNextStepLoadAdobe();
                        this.nextStepReady.emit(true);
                    }
                });
        }
    }

    get pinFormControl(): AbstractControl {
        return this.pinForm.get(this.pinFormControlName);
    }

    get getPinErrorMessage(): string | undefined {
        if (this.pinFormControl.hasError('required')) {
            return this.granteePinContent.frmfUniquePin.errEnterPin;
        }

        if (this.pinFormControl.hasError('pattern')) {
            return this.granteePinContent.frmfUniquePin.errEnterValidPin;
        }
        return undefined;
    }
}
