<h1 class="c11n-text-xl-headline">{{ grantorWhoGivingAccessContent.heading }}</h1>

<div
    class="justify-content-center negative-margin col-12 col-sm-9 col-md-9 col-lg-8 col-xl-8 col-xxl-8"
>
    <ng-container *ngIf="!isMVP2Enabled">
        <zci-triage-mvp-tile
            tabindex="0"
            role="button"
            [title]="grantorWhoGivingAccessContent.frmfIndividualAlreadyHasPermission"
            (click)="navigateSelectUser()"
            (keydown.enter)="navigateSelectUser()"
            (keydown.space)="navigateSelectUser()"
        >
        </zci-triage-mvp-tile>
        <zci-triage-mvp-tile
            tabindex="0"
            role="button"
            [title]="grantorWhoGivingAccessContent.frmfIndividualHasOwnVanguardAccount.heading"
            [subTitle]="grantorWhoGivingAccessContent.frmfIndividualHasOwnVanguardAccount.subheading"
            (click)="navigateAddSomeoneElse()"
            (keydown.enter)="navigateAddSomeoneElse()"
            (keydown.space)="navigateAddSomeoneElse()"
        >
        </zci-triage-mvp-tile>
        <zci-triage-mvp-tile
            tabindex="0"
            role="button"
            [title]="grantorWhoGivingAccessContent.frmfIndividualWithoutVanguardAccount.heading"
            [description]="grantorWhoGivingAccessContent.frmfIndividualWithoutVanguardAccount.labelText"
            (click)="navigateToFormFromNonVGUser()"
            (keydown.enter)="navigateToFormFromNonVGUser()"
            (keydown.space)="navigateToFormFromNonVGUser()"
        >
        </zci-triage-mvp-tile>
        <zci-triage-mvp-tile
            tabindex="0"
            role="button"
            [title]="grantorWhoGivingAccessContent.frmfOrganizationTrust.heading"
            [description]="grantorWhoGivingAccessContent.frmfOrganizationTrust.labelText"
            (click)="navigateToFormFromOrgTrust()"
            (keydown.enter)="navigateToFormFromOrgTrust()"
            (keydown.space)="navigateToFormFromOrgTrust()"
        >
        </zci-triage-mvp-tile>
    </ng-container>
    <ng-container *ngIf="isMVP2Enabled">
        <zci-triage-mvp-tile
            tabindex="0"
            role="button"
            [title]="grantorWhoGivingAccessContent.individualOrProfessionalFormField.heading"
            [subTitle]="grantorWhoGivingAccessContent.individualOrProfessionalFormField?.subheading"
            [description]="grantorWhoGivingAccessContent.individualOrProfessionalFormField.labelText"
            (click)="navigateSelectUser()"
            (keydown.enter)="navigateSelectUser()"
            (keydown.space)="navigateSelectUser()"
        >
        </zci-triage-mvp-tile>
        <zci-triage-mvp-tile
            tabindex="0"
            role="button"
            [title]="grantorWhoGivingAccessContent.entireOrgOrTrustFormField.heading"
            [subTitle]="grantorWhoGivingAccessContent.entireOrgOrTrustFormField?.subheading"
            [description]="grantorWhoGivingAccessContent.entireOrgOrTrustFormField.labelText"
            (click)="navigateToFormFromOrgTrust()"
            (keydown.enter)="navigateToFormFromOrgTrust()"
            (keydown.space)="navigateToFormFromOrgTrust()"
        >
        </zci-triage-mvp-tile>
    </ng-container>
</div>