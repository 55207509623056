import { AdobeMethods } from '../../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsProcessStep } from '../../../../services/adobe-launch/adobe-launch.process';
import { AnalyticsCtaType } from '../../../../services/adobe-launch/adobe-launch.cta';
import { AdobeGranteeFlowType } from '../grantee-flows.interface';

export function getAdobeConfigData(granteeFlow: AdobeGranteeFlowType) {
    const flowAdobeDataMap = {
        [AdobeGranteeFlowType.NEW_INFO_ONLY] : {
            pageName: AdobePageName.GRANTEE_SUCCESS_GRANTEE_NOT_ON_LIST_INFO_ONLY,
            ctaLocation: AdobeAnalyticsProcessStep.GRANTEE_INFO_ONLY_NOT_ON_LIST,
        },
        [AdobeGranteeFlowType.NEW_LIMITED] : {
            pageName: AdobePageName.GRANTEE_SUCCESS_GRANTEE_NOT_ON_LIST_LIMITED,
            ctaLocation: AdobeAnalyticsProcessStep.GRANTEE_LIMITED_NOT_ON_LIST,
         },
        [AdobeGranteeFlowType.EXISTING_LIMITED] : {
            pageName: AdobePageName.GRANTEE_SUCCESS_GRANTEE_ON_LIST,
            ctaLocation: AdobeAnalyticsProcessStep.GRANTEE_REG_QUESTIONS_GRANTEE_ON_LIST,
        },
    };

    const { pageName, ctaLocation } = flowAdobeDataMap[granteeFlow];

    return {
        ngOnInit: [
            {
                method: AdobeMethods.PAGE_LOAD,
                data: [pageName],
            },
        ],
        goToDashboard: [
            {
                method: AdobeMethods.TRACK_ACTION,
                data: [
                    {
                        location: [ctaLocation],
                        ctaName: AdobeAnalyticsProcessStep.GRANTEE_VIEW_MY_DASHBOARD,
                        ctaType: AnalyticsCtaType.BUTTON,
                        fireTrackLink: true,
                    },
                ],
            },
        ],
    };
}
