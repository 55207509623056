import { Component, EventEmitter, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GafSelectExistingUserMvpComponent } from './gaf-select-existing-user-mvp/gaf-select-existing-user-mvp.component';
import { UserTypeSelection } from '../../../../enums/user-type-selection.enum';
import { Observable, Subscription } from 'rxjs';
import { selectAddUserStepperSelectedUserType } from '../../../../store/selectors/grant-revoke-permissions.selector';
import { Store } from '@ngrx/store';
import { updateAddUserStepperSelectedUserType } from '../../../../store/actions/add-user-stepper-flow.action';
import { ACCOUNT_ID_PARAM } from '../../../../constants/application-routes';
import { ActivatedRoute } from '@angular/router';
import { GafAddSomeoneElseMvpComponent } from './gaf-add-someone-else-mvp/gaf-add-someone-else-mvp.component';
import { CtaCallUsLinkLocations } from '../../../../services/adobe-launch/adobe-launch.cta';

@Component({
    selector: 'zci-gaf-select-user-step-mvp',
    standalone: true,
    imports: [CommonModule, GafSelectExistingUserMvpComponent, GafAddSomeoneElseMvpComponent],
    templateUrl: './gaf-select-user-step-mvp.component.html',
    styleUrls: ['./gaf-select-user-step-mvp.component.scss'],
})
export class GafSelectUserStepMvpComponent implements OnInit, OnDestroy {
    @Input() nextStepClicked: EventEmitter<any>;
    @Input() nextStepReady: EventEmitter<boolean>;
    @Input() locationChanged: EventEmitter<any>;

    readonly subs: Subscription = new Subscription();

    accountId: string;

    userTypeSelection$: Observable<UserTypeSelection> = this.store.select(
        selectAddUserStepperSelectedUserType,
    );

    protected readonly userTypeSelection = UserTypeSelection;

    constructor(
        private store: Store,
        private readonly activatedRoute: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        this.accountId = this.activatedRoute.snapshot.params[ACCOUNT_ID_PARAM];
        this.subs.add(
            this.userTypeSelection$.subscribe((value: UserTypeSelection) => {
                const location =
                    value === UserTypeSelection.EXISTING
                        ? CtaCallUsLinkLocations.ZCI_SELECT_PERSON_STEP
                        : CtaCallUsLinkLocations.ZCI_ADD_SOMEONE_ELSE;
                this.locationChanged.emit(location);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onAddSomeoneElse() {
        this.store.dispatch(
            updateAddUserStepperSelectedUserType({ selectedUserType: UserTypeSelection.NEW }),
        );
    }

    onNextStepReady(e: boolean): void {
        this.nextStepReady.emit(e);
    }
}
