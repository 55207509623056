import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StoreConstant } from '../store.constant';
import { GranteeState } from '../state/grantee.state';
import { AccessTypeSelection } from '../../../common/enums/access-type-selection.enum';
import { GranteeIneligibleReasons } from '../../../common/enums/ineligible-scenarios.enum';

export const selectGranteeAccessType = createSelector(
    createFeatureSelector(StoreConstant.reducers.granteeReducer),
    (state: GranteeState): AccessTypeSelection => state.accessType,
);

export const selectGranteeIneligibleReason = createSelector(
    createFeatureSelector(StoreConstant.reducers.granteeReducer),
    (state: GranteeState): GranteeIneligibleReasons => state.granteeIneligibleReason,
);

export const selectGranteeState = createSelector(
    createFeatureSelector(StoreConstant.reducers.granteeReducer),
    (state: GranteeState) => state,
);

export const selectGranteeRegulatoryQuestions = createSelector(
    createFeatureSelector(StoreConstant.reducers.granteeReducer),
    (state: GranteeState) => state.granteeRegulatoryQuestions,
);
