import {
	Component,
	EventEmitter,
	HostBinding,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FlowButtons, FlowConfig, StepConfig, StepperAdobeTag } from './flow-config.interface';
import { Store } from '@ngrx/store';
import { BreakpointObserverService } from '../../services/breakpoint-observer/breakpoint-observer.service';
import { ContentUtil } from '../../utils/content/content.util';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { resetGrantRevokePermissionState } from '../../store/actions/grant-revoke-permissions.action';
import { Router } from '@angular/router';
import { APPLICATION_ROUTES } from '../../constants/application-routes';
import { AdobeLaunchService } from '../../services/adobe-launch/adobe-launch.service';
import { DrawerComponent } from '@vg-constellation/angular-16/drawer';
import { VgnService } from '../../services/vgn/vgn.service';
import { adobeTagging } from './adobe-tagging-data';
import { FlowConfigService, FlowKey } from '../../services/flow-config-service/flow-config.service';
import { ModalDialogComponent } from '@vg-constellation/angular-16/modal-dialog';

@Component({
	selector: 'zci-stepper',
	templateUrl: './stepper.component.html',
	styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit, OnDestroy {
	@Input() flow: FlowKey = 'TEST';

	isCurrentStepStandalone: boolean;

	flowConfig: FlowConfig;

	currentStep: number = null;
	currentStepConfig: StepConfig = null;

	stepLabels: string[];

	buttons: FlowButtons = {
		contactUs: true,
		quit: true,
	};

	isStepIndicatorNeeded: boolean;

	@ViewChild('quitButtonModalDialog', { static: false })
	quitButtonModalDialog!: ModalDialogComponent;

	@ViewChild('gafDrawer', { static: false })
	drawerComponent!: DrawerComponent;

	@HostBinding('class.push')
	pushContent: boolean = false;

	@HostBinding('class.gaf-drawer-open')
	drawerOpen: boolean = false;

	isMobileView$: Observable<boolean> = this.breakpointObserver.isMobileView$;

	isTabletView$: Observable<boolean> = this.breakpointObserver.isTabletView$;

	nextStepFromChild: EventEmitter<any> = new EventEmitter();

	nextStepClicked: EventEmitter<any> = new EventEmitter();

	locationChanged: EventEmitter<any> = new EventEmitter();

	isWaiting$: BehaviorSubject<any> = new BehaviorSubject(false);

	destroyed$: Subject<void> = new Subject();

	//TODO: move this content out from stepper component
	addUserFlowGrantorContent = ContentUtil.grantorFragmentContent.add_user;

	title: string = ContentUtil.wealthManagementSupportContent;

	contactPhone: string = ContentUtil.globalRMSupportContactPhoneNumber;

	constructor(
		private readonly store: Store,
		private readonly router: Router,
		private readonly breakpointObserver: BreakpointObserverService,
		private readonly adobeLaunchService: AdobeLaunchService,
		private readonly vgnService: VgnService,
		private readonly flowConfigService: FlowConfigService,
	) {}

	ngOnInit(): void {
		this.loadFlow();
		this.vgnService.setMinimalNav(true);
	}

	ngOnDestroy(): void {
		this.vgnService.setMinimalNav(false);
		this.destroyed$.next();
		this.destroyed$.complete();
	}

	private loadFlow() {
		this.flowConfig = this.flowConfigService.getFlowConfig(this.flow);

		const labels = this.flowConfig.steps.map((item) => item.title);

		if (this.flowConfig?.isLastStepStandalone) {
			labels.pop();
		}

		this.stepLabels = labels;

		this.buttons = Object.assign(this.buttons, this.flowConfig?.buttons);

		this.isStepIndicatorNeeded = this.flowConfig.steps.length > 2;

		this.currentStep = 0;
		this.changeStep();
	}

	private changeStep(change = 0) {
		if (this.currentStep + change < 0) {
			this.exitFlow();
			return;
		}

		if (this.currentStep + change >= this.flowConfig.steps.length) {
			return;
		}

		this.isWaiting$.next(false);
		this.currentStep += change;
		const stepConfig = this.flowConfig.steps[this.currentStep];

		if (
			this.flowConfig.isLastStepStandalone &&
			this.currentStep === this.flowConfig.steps.length - 1
		) {
			this.isCurrentStepStandalone = true;
		}

		if (this.nextStepFromChild?.unsubscribe) {
			this.nextStepFromChild.unsubscribe();
		}

		if (this.locationChanged?.unsubscribe) {
			this.locationChanged.unsubscribe();
		}

		this.nextStepFromChild = new EventEmitter<boolean>();
		this.nextStepClicked = new EventEmitter<any>();
		this.locationChanged = new EventEmitter<any>();

		this.nextStepFromChild.pipe(takeUntil(this.destroyed$)).subscribe((response) => {
			this.isWaiting$.next(false);
			if (response) {
				this.changeStep(1);
			}
		});

		this.locationChanged
			.pipe(takeUntil(this.destroyed$))
			.subscribe((locationValue: string) =>
				this.updateCurrentStepConfigAdobeLocation(locationValue),
			);

		this.currentStepConfig = {
			...stepConfig,
			inputs: {
				...stepConfig.inputs,
				nextStepReady: this.nextStepFromChild,
				nextStepClicked: this.nextStepClicked,
				locationChanged: this.locationChanged,
			},
		};
	}

	private getAdobeTag(action: StepperAdobeTag, location: string) {
		return adobeTagging[action] && adobeTagging[action](location);
	}

	private applyAdobeTagging(action: StepperAdobeTag) {
		let tagData = [];

		if (this.currentStepConfig?.adobeLocation) {
			tagData = this.getAdobeTag(action, this.currentStepConfig.adobeLocation);
		}

		if (
			this.currentStepConfig?.customAdobeTags &&
			this.currentStepConfig.customAdobeTags[action]
		) {
			tagData = this.currentStepConfig.customAdobeTags[action];
		}

		if (!Array.isArray(tagData)) {
			return;
		}

		tagData.forEach((item) => {
			this.adobeLaunchService[item.method].apply(this.adobeLaunchService, item.data);
		});
	}

	openModalDialog(): void {
		this.quitButtonModalDialog.openModalDialog();
	}

	quit(): void {
		this.applyAdobeTagging('onQuit');
		this.exitFlow();
	}

	stayOnCurrentStep(): void {
		this.quitButtonModalDialog.closeDialogModal();
	}

	backButton(): void {
		this.applyAdobeTagging('onBack');
		this.changeStep(-1);
	}

	nextButton(): void {
		this.isWaiting$.next(true);
		this.nextStepClicked.emit();
	}

	contactUs(): void {
		if (this.drawerOpen) {
			this.closeContactUs();
		} else {
			this.openContactUs();
			this.applyAdobeTagging('onContactUs');
		}
	}

	onDrawerStateChange(event: any): void {
		this.drawerOpen = event.isDrawerOpen;
		if (!this.drawerComponent.isModal) {
			this.pushContent = event.isDrawerOpen;
		}
	}

	private openContactUs(): void {
		this.drawerComponent.openDrawer();
	}

	private closeContactUs(): void {
		this.drawerComponent.closeDrawer();
	}

	private exitFlow(): void {
		this.store.dispatch(resetGrantRevokePermissionState());
		this.router.navigate([APPLICATION_ROUTES.OWN_ACCOUNTS]);
	}

	private updateCurrentStepConfigAdobeLocation(value: string | null): void {
		this.currentStepConfig.adobeLocation = value;
	}
}
