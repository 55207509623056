import { Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ElementConfig } from '../../interfaces/element-config.interface';

function getContentWithDynamicNumber(text: string, num?: number): string {
    return text.replace('{{}}', num?.toString() || '');
}

function addClassToLinks(htmlString: string): string {
    const regex = /<a([^>]*)>/g;
    return htmlString.replace(regex, '<a class="c11n-link"$1>');
}

function removeHTMLtags(contentString: string): string {
    const htmlTagsRegex = new RegExp(/<\/?[^>]+(>|$)/g);
    return contentString.replace(htmlTagsRegex, '');
}

/**
 * @description
 * Creates and configures HTML Element based on config file
 * @param { Renderer2 } renderer
 * @param { ElementConfig } config
 * @returns { HTMLElement } - HTML Element created with renderer based on configuration
 */
function createAndConfigureElement(renderer: Renderer2, config: ElementConfig): HTMLElement {
    const element = renderer.createElement(config.element);

    for (const [key, value] of Object.entries(config.attributes)) {
        renderer.setAttribute(element, key, value);
    }

    if (config.textContent) {
        const text = renderer.createText(config.textContent);
        renderer.appendChild(element, text);
    }

    if (config.children) {
        for (const childConfig of config.children) {
            const childElement = createAndConfigureElement(renderer, childConfig);
            renderer.appendChild(element, childElement);
        }
    }

    return element;
}

function sanitizeHtml(sanitizer: DomSanitizer, htmlString: string): SafeHtml {
    return sanitizer.bypassSecurityTrustHtml(htmlString);
}

export default {
    getContentWithDynamicNumber,
    addClassToLinks,
    removeHTMLtags,
    createAndConfigureElement,
    sanitizeHtml,
};
