import { AdobeMethods } from '../../../services/adobe-launch/adobe-launch.methods';
import { AdobePageName } from '../../../services/adobe-launch/adobe-launch.pages';
import { AdobeAnalyticsProcessStep } from '../../../services/adobe-launch/adobe-launch.process';
import { CtaNames } from '../../../services/adobe-launch/adobe-launch.cta';

export const configData = {
    adobePageLoad: [
        {
            method: AdobeMethods.PAGE_LOAD,
            data: [AdobePageName.GRANTEE_WEB_REG],
        },
    ],
    adobeGetStartedInfoOnly: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GRANTEE_NOT_WEB_REGISTERED,
                    ctaName: CtaNames.GET_STARTED_INFO_ONLY,
                    ctaType: 'button',
                },
            ],
        },
    ],
    adobeGetStartedLimited: [
        {
            method: AdobeMethods.TRACK_ACTION,
            data: [
                {
                    location: AdobeAnalyticsProcessStep.GRANTEE_NOT_WEB_REGISTERED,
                    ctaName: CtaNames.GET_STARTED_LIMITED,
                    ctaType: 'button',
                },
            ],
        },
    ],
};
