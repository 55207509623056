export const CONSTANTS = {
    PAGINATOR_PAGE_SIZE: 12,
    NAME_PATTERN: /^(?!\s)[a-z0-9A-Z\-.'& ]*(?<!\s)$/,
    ACCOUNT_NUMBER_VALIDATION_PATTERN: /^(?=[0-9]*$)(?:.{6}|.{8}|.{11})$/,
    PHONE_NUMBER_VALIDATION_PATTERN: /^([2-9][0-9]{2})-[0-9]{3}-[0-9]{4}$/,
    EMAIL_VALIDATION_PATTERN: /^['A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
    DATE_OF_BIRTH_VALIDATION_PATTERN: /\d{2}\/\d{2}\/\d{4}/,
    UNIQUE_PIN_VALIDATION_PATTERN: /^[a-zA-Z0-9]{8}$/,
    SEARCH_PERSON_ATTEMPT_MAX: 4,
    DEFAULT_NON_PROD_PORT: '53443',
    ADDRESS_HOLD_RESTRICTION: 'AHLD',
    NUM_ACCOUNTS_PER_TP1_CALL: 25,
};

export const CONTENT_PLACEHOLDERS = {
    CONTACT_PERSON_TYPE: 'contactPersonType',
    FULL_NAME: 'fullName',
    FIRST_NAME: 'firstName',
};

export const COOKIES = {
    CL_SPOID: 'cl_spoid',
    LOGONNONPRODPORT: 'LOGONNONPRODPORT',
    MONOLITH_TARGET: 'monolithtarget',
};

export default CONSTANTS;
